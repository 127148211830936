.Notfound__Box {
  display: grid;
}

.Notfound__Div {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100vh;

  h1 {
    color: #3f4254 !important;
    text-align: center;
    font-size: 120px;
  }

  p {
    color: #3f4254 !important;
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.Notfound__Box .Biz__NotBtn {
  padding: 6px 12px;
  border: none;
  background-color: var(--Main-Secondary-color);
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  width: max-content;
  text-decoration: none !important;
  margin: auto;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
