.Card {
  display: grid;
  grid-template-rows: max-content auto;
  overflow: auto;

  .CardBack {
    background: var(--Main-Page-title-color) !important;
    margin: 0px !important;
    border-bottom: 1px solid #8098b5 !important;
    display: grid;
    grid-template-columns: auto max-content max-content;
    gap: 8px;
  }

  .DynBiz__Customer_AddPopup_FormContentSectionHead {
    background: #f3f6f9;
    padding: 6px 12px;
    margin: 12px 0px 6px 0px;
    border-top: 1px solid #d6dbdf;
    border-bottom: 1px solid #d6dbdf;
  }

  .DynBiz_Customer_AddPopup__TabList {
    color: #1685cb;
    font-size: 12px;
    list-style: none;
    display: grid;
    align-items: center;
    padding: 3px 12px;
    margin-right: 4px;
    cursor: pointer;
    user-select: none !important;
    border-radius: 0.18rem;
    white-space: nowrap;
    font-family: "Poppins", sans-serif;
    line-height: 20px;
    font-weight: 500;
  }

  .DynBiz_ActiveCustomer_AddPopup__TabList {
    background-color: #1685cb;
    color: #fff;
  }

  .DynBiz_Customer_AddPopup__TabPanel {
    display: flex;
    flex-direction: row;
  }
}

.ModalHeadSearchBox {
  display: flex;
  margin-left: auto;
  margin-top: -3px;
  margin-right: 8px;
}
