.DynBiz__ColGridSplitter {
  grid-row: 1;
  cursor: col-resize;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==);
  grid-column: 2;
  background-color: var(--Sub-Header-Color);
  background-repeat: no-repeat;
  background-position: 50%;
  border-top: 1px solid var(--Main-Page-Background);
  border-bottom: 1px solid var(--Main-Page-Background);
}

.DynBiz__RowGridSplitter {
  grid-column: 1;
  background-color: var(--Sub-Header-Color);
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: row-resize;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=);
  border-left: 1px solid var(--Main-Page-Background);
  border-right: 1px solid var(--Main-Page-Background);
}
