@import "./Widgets/Tooltip.scss";
@import "./Common/DataGrid/Cust_AgGrid.scss";
@import "./Common/DataGrid/DataTable.scss";
@import "./Layout/SplitGrid.scss";
@import "./Layout/ListDetail.scss";
@import "./~ag-grid-community/styles/ag-grid.css";
@import "./~ag-grid-community/styles/ag-theme-alpine.css";

:root {
  --lg-main-color: #40b4e6;
  --lg-dark-textcolor: #063052;
  --lg-greydark-textcolor: #3f4254;
  --lg-light-textcolor: #ffffff;
  --lg-light-backcolor: #ffffff;
  --Sub-Header-Color: #6f7e95;
  --Main-Primary-Grey-Color: #6f7e95;
  --Main-Footer-Color: #6f7e95;
  --Main-scrollbar-color: #5d6d7e;
  --Main-Header-Icon-Color: #3699ff;
  --Main-Primary-Color: #3699ff;
  --Main-Secondary-color: #50c6d0;
  --Main-Dark-text-color: #3f4254;
  --Main-light-text-color: #fff;
  --Main-Header-SelectedIcon-Color: #3f4254;
  --Main-Header-IconText-Color: #3f4254;
  --Main-Page-Background: #f0f6ff;
  --Main-Page-title-color: #ffe3aa;
  --Header-Page-Title-Back-Color: #d6dbdf;
  --Main-Primary-lightBlue-Color: #52bcf6;
  --Main-Primary-red-Color: #f64e60;
  --Main-Primary-CancelBtn-Color: #85929e;
  --BizLabel-Primary-Color: #0ab4ff;
  --BizLabel-warning-Color: #ffa800;
  --BizLabel-Success-Color: #1bc5bd;
  --BizLabel-danger-Color: #f64e60;
  --BizLabel-green-Color: #1fbe27;
  --BizLabel-orange-Color: #f55718;
  --BizLabel-yellow-Color: #fced44;
  --lg-ContextMenu-zIndex: 999;
  --lg-PopupOverlay-zIndex: 9999;
  --lg-Popup-zIndex: 9999999;
  --lg-SelectPopup-zIndex: 99999999;
  --lg-SearchPopup-zIndex: 9999999999;
  --lg-ConfirmBox-zIndex: 99999999999999999999;
  --lg-SessionTimoutPopup-zIndex: 999999999999999;
}
