:root {
  --lg-logo-size: 62px;
  --lg-footer-ftsize: 16px;
  --login-ImgBox-width: 100%;
  --login-ImgDiv-row: 50px auto;
  --login-MainForm-row: 105px auto;
  --login-MainDiv-row: 48% 52%;
  --login-MainForm-width: 72%;
  --login-smallfontsize: 16px;
  --login-mediumfontsize: 16px;
  --login-largefontsize: 16px;
  --lg-main-color: #40b4e6;
}

.MainDiv {
  display: grid;
  grid-template-columns: var(--login-MainDiv-row);
  height: 100vh;
  background: #fff;
}

.ImgDiv {
  background: var(--lg-main-color);
  height: 100%;
  display: grid;
  align-items: center;
  align-self: center;
  grid-template-rows: var(--login-ImgDiv-row);
}

.SupportDiv {
  border-bottom: 1px solid #fff;
  height: 100%;
  padding: 0px 16px;
}

.SupportCard {
  height: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 16px;

  .supportext {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: flex-end;
    margin: 0px;
    outline: none;

    span {
      color: #063052;
      font-size: 26px;
      font-weight: bold;
      padding-left: 4px;
    }
  }

  .supporicon {
    color: #ffffff;
    font-size: var(--login-smallfontsize);
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    outline: none;
    letter-spacing: 1px;

    &:hover {
      text-decoration: underline;
    }

    &:nth-child(3) {
      justify-content: flex-start;
      margin: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .Icon {
      color: #063052;
      font-size: 30px;
      padding-right: 10px;
    }
  }
}

.ImgBox {
  height: 100%;
  display: grid;
  grid-template-rows: auto;
  align-items: center;

  .SvImg {
    width: var(--login-ImgBox-width);
    margin: auto;
  }
}

.SocialDiv {
  height: 100%;
  width: 100%;
}

.socialbtntxt {
  font-size: var(--login-smallfontsize);
  line-height: 30px;
  display: block;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 10px;
}

.socialbtns {
  position: relative;
  height: 100%;
  margin: auto;
  font-size: 0;
  text-align: center;

  .socialbtn {
    display: inline-block;
    background-color: #fff;
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin: 0 10px;
    text-align: center;
    position: relative;
    overflow: hidden;
    border-radius: 28%;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
    opacity: 1;

    &:before {
      content: "";
      width: 120%;
      height: 120%;
      position: absolute;
      transform: rotate(45deg);
    }

    .Icon {
      font-size: 38px;
      vertical-align: middle;
    }

    transition: all 0.35s;
    transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);

    &:before,
    .Icon {
      transition: all 0.35s;
      transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
    }

    &:before {
      top: 150%;
      left: -100%;
    }

    .Icon {
      transform: scale(0.8);
    }

    &.facebookbtn {
      &:before {
        background-color: #3b5998;
      }

      .Icon {
        color: #3b5998;
      }
    }

    &.twitterbtn {
      &:before {
        background-color: #3cf;
      }

      .Icon {
        color: #3cf;
      }
    }

    &.linkedinbtn {
      &:before {
        background-color: #00aff0;
      }

      .Icon {
        color: #00aff0;
      }
    }

    &.websitebtn {
      &:before {
        background-color: #dc4a38;
      }

      .Icon {
        color: #dc4a38;
      }
    }

    &:focus:before,
    &:hover:before {
      top: -10%;
      left: -10%;
    }

    &:focus .Icon,
    &:hover .Icon {
      color: #ffffff;
      transform: scale(1);
    }
  }
}

.FormDiv {
  box-shadow: -2px 0 22px rgba(0, 0, 0, 0.12);
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: auto 40px;
  align-items: center;
}

.MainForm {
  box-shadow: 0px 2px 30px #ccc4;
  width: var(--login-MainForm-width);
  display: grid;
  grid-template-rows: var(--login-MainForm-row);
  margin: auto !important;
  padding: 0px 40px;
  background: #fff;
  position: relative;
  border-radius: 6px;
  border: 1px solid #a7aaaf;
}

.logoBar {
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  padding: 26px 10px 20px 10px;
  border-bottom: 1px solid #eaeaea;
}

.clientlogo {
  display: flex;
  height: var(--lg-logo-size);
  justify-content: flex-start;
  width: auto;
  align-items: center;
}

.biznextlogo {
  display: flex;
  height: var(--lg-logo-size);
  justify-content: flex-end;
  width: max-content;
  margin-left: auto;
  align-items: center;
}

.logoBar .clientlogo .SvImg {
  max-width: 100%;
  max-height: 100%;
  height: inherit;
}

.logoBar .biznextlogo .SvImg {
  height: 100%;
}

.FormGroup {
  position: relative;
  display: table;
  border-collapse: separate;
  margin: 26px 0px;
}

.ErrorDiv {
  position: absolute;
  left: 4px;
  display: flex;
}

.ErrorIcon {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  margin: 0 4px 0 0;

  .Icon {
    font-size: 10px;
    color: #e54b4b;
    vertical-align: top;
    margin-top: 4px;
  }
}

.ErrorTxt {
  font-weight: 500;
  font-size: 12px;
  color: #e54b4b;
  margin: 2px 0 0;
}

.Form_error {
  .FormIcon,
  .Form__control {
    border-color: #de2828 !important;
  }

  .FormIcon .Icon {
    color: #de2828 !important;
  }
}

.FormGroup:nth-child(1) {
  margin: 30px 0px 0px 0px;
}

.FormIcon {
  background: #fff !important;
  border-right: 0px !important;
  display: table-cell;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1px;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  color: #555;
  text-align: center;
  border: 1px solid #ccced1;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  .Icon {
    font-size: var(--login-smallfontsize);
    color: #6d6c6c;
  }
}

.Form__control {
  display: table-cell;
  position: relative;
  width: 100%;
  border: 1px solid #ccced1 !important;
  border-radius: 4px;
  height: 40px;
  color: #555;
  padding: 6px 36px 6px 12px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  &:focus {
    border-color: #66afe9;
    outline: 0;
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: #eee;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    opacity: 0.7 !important;
  }
}

.FormBtn {
  float: right;
  box-shadow: 0px 2px 30px #ccc4;
  background: linear-gradient(
    135deg,
    #50c6d0,
    #76d1d9 50%,
    #70cdd5 51%,
    #50c6d0 71%,
    #52bbc4
  );
  padding: 10px 20px;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 600;
  margin: auto;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  text-transform: capitalize;
  background-size: 400% 400%;
  animation: LoginbtnAnimation 3s ease infinite;
  animation: LoginbtnAnimation 3s ease infinite;
  border: medium none;
  outline: none;

  &:hover,
  &:focus {
    background: Var(--lg-main-color);
  }
}

.FormBtnActive {
  background: Var(--lg-main-color) !important;
  pointer-events: none !important;

  .btn__spinner {
    display: block;
  }

  .BtnTxt {
    display: none;
  }
}

.btn__spinner {
  display: none;
  align-items: center;
  justify-content: center;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  opacity: 1;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    opacity: 1;
  }
}

.btn__bounce1,
.btn__bounce2,
.btn__bounce3 {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  animation: loginbtn__animation 1.4s ease-in-out infinite both;
}

.btn__bounce1 {
  animation-delay: -0.32s;
}

.btn__bounce2 {
  animation-delay: -0.16s;
}

.pass_icon {
  font-size: 26px;
  padding: 0px 0px 0px 6px;
  position: relative;
  z-index: 2;
  color: #555;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: rgba(241, 245, 246, 0.8);
  color: #000;
  white-space: nowrap;
  text-align: center;
  padding: 6px 0;
  font-size: var(--lg-footer-ftsize);
  border-top: 1px solid #d6d6d6;

  span {
    font-family: "Quicksand", sans-serif;
  }
}

.TooglePasswordBox {
  position: absolute;
  right: 12px;
  background: transparent;
  border: 0px;
  height: 100%;
  padding-top: 5px;

  &:active {
    box-shadow: none !important;
    transform: none !important;
  }
}

@media (max-width: 1200px) {
  :root {
    --login-MainDiv-row: 200px auto;
  }

  .SupportDiv,
  .ImgBox,
  .SocialDiv {
    display: none;
  }
}

@media (max-width: 900px) {
  :root {
    --login-MainDiv-row: 40px auto;
  }
}

@media (max-width: 700px) {
  :root {
    --login-MainForm-width: 92%;
  }

  .MainForm {
    box-shadow: 0px 2px 30px #ccc4;
  }
}

@media (max-height: 700px) {
  :root {
    --login-ImgDiv-row: 50px auto 0px;
  }
}

@media (max-height: 500px) {
  :root {
    --login-ImgDiv-row: 0px auto 0px;
  }

  .SupportCard {
    display: none;
  }
}

@media (max-width: 575px) {
  :root {
    --login-MainForm-row: 80px auto;
    --lg-logo-size: 30px;
    --lg-footer-ftsize: 10px;
    --login-MainForm-width: calc(100% + 40px);
  }

  .clear___toast {
    min-width: 300px;
    right: 0px;
  }

  .MainForm {
    box-shadow: 0px 2px 30px #ccc4;
    margin-left: -40px !important;
    box-shadow: none;
    border: none;
    border-radius: 0px;
    padding: 0px 20px;
  }
}

@media (min-width: 2200px) {
  :root {
    --lg-logo-size: 60px;
    --login-ImgDiv-row: 80px auto 260px;
    --login-MainForm-row: 120px auto;
    --lg-footer-ftsize: 26px;
    --login-ImgBox-width: 70%;
    --login-smallfontsize: 28px;
    --login-MainForm-width: 64%;
  }

  .SupportCard {
    .supporicon .Icon {
      font-size: 28px;
    }

    .supportext span {
      font-size: 32px;
    }
  }

  .socialbtntxt {
    margin-bottom: 40px;
  }

  .socialbtns .socialbtn {
    width: 120px;
    height: 120px;
    line-height: 116px;
  }

  .SupportCard .supportext {
    font-size: 32px;
  }

  .socialbtns .socialbtn .Icon {
    font-size: 68px;
  }

  .footer {
    padding: 16px 0px;
  }

  .Form__control {
    height: 60px;
    font-size: 20px;
  }

  .FormBtn {
    line-height: 40px;
    padding: 10px 40px;
    font-size: 20px;
    margin-top: 30px;
  }

  .pass-icon {
    font-size: 28px;
    margin-top: -40px;
  }

  .FormGroup {
    &:nth-child(1) {
      margin: 40px 0px 0px 0px;
    }

    margin: 30px 0px 0px 0px;
  }
}
.errorMsg {
  color: red;
  font-weight: 400;
  font-size: 14px;
}

@keyframes LoginbtnAnimation {
  0% {
    background-position: 0% 31%;
  }

  50% {
    background-position: 100% 70%;
  }

  100% {
    background-position: 0% 31%;
  }
}

@keyframes loginbtn__animation {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes RfadeInDown {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.OtpGroup {
  display: grid;
  grid-template-rows: repeat(2, max-content);
  justify-content: center;
  padding: 20px 0px;

  &.ErrorOtpGroup {
    > div > div input {
      border: 1px solid #de2828 !important;
    }

    .ErrorMsgBox {
      display: flex !important;
      span {
        font-weight: 600;
        font-size: 15px;
        color: #e54b4b;
        margin: 6px 6px 0;
      }
    }
  }

  > h3 {
    font-size: 20px;
    color: #141c54;
    margin-bottom: 12px;
    font-family: var(--fg-headingsFont);
    font-weight: 900;
  }

  > p {
    font-size: 14px;
    color: #5d6d7e;
    font-family: var(--fg-headingsFont);
    font-weight: 600;
    margin-bottom: 40px;

    > span {
      font-size: 16px !important;
      color: #34495e;
      font-family: var(--fg-headingsFont);
      font-weight: 900;
      text-decoration: underline;
    }
  }

  .ResendGroup {
    display: grid;
    grid-template-columns: repeat(3, max-content) !important;
    gap: 8px;
    align-items: center;
    margin-top: 12px;

    p {
      font-size: 13px;
      color: #85929e;
      font-family: var(--fg-headingsFont);
      font-weight: 600;
    }

    > button {
      background: #ffffff !important;
      line-height: normal !important;
      height: max-content !important;
      padding: 0px !important;
      font-size: 14px !important;
      color: #34495e;
      font-family: var(--fg-headingsFont);
      font-weight: 900;
      white-space: nowrap !important;
      width: max-content !important;
      margin: 0px !important;
      box-shadow: none !important;
      border: 0px !important;

      &.disabled {
        pointer-events: none !important;
        opacity: 0.4 !important;
      }
    }

    > span {
      font-size: 14px !important;
      color: #34495e;
      font-family: var(--fg-headingsFont);
      font-weight: 900;
    }
  }

  > div {
    display: grid !important;
    grid-template-columns: repeat(6, 66px) !important;

    > div {
      display: grid !important;

      input {
        font-family: var(--fg-headingsFont);
        font-weight: 600;
        color: #2e4053;
        border-radius: 6px;
        width: 54px !important;
        height: 50px !important;
        font-size: 24px;
        border: 1px solid #adafc1;
        box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%), 0 0 1px 0 rgb(0 0 0 / 15%);

        &:focus {
          border: 2px solid #1966d2;
        }
      }
    }
  }
}

.ButtonGroup {
  display: flex;
  justify-content: flex-end;
}

.OTPFormBtn {
  float: right;
  box-shadow: 0px 2px 30px #ccc4;
  background: linear-gradient(
    135deg,
    #50c6d0,
    #76d1d9 50%,
    #70cdd5 51%,
    #50c6d0 71%,
    #52bbc4
  );
  padding: 10px 20px;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 600;
  margin-left: auto;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  text-transform: capitalize;
  background-size: 400% 400%;
  animation: LoginbtnAnimation 3s ease infinite;
  animation: LoginbtnAnimation 3s ease infinite;
  border: medium none;
  outline: none;

  &:hover,
  &:focus {
    background: Var(--lg-main-color);
  }
}

.OTPFormBtnActive {
  background: Var(--lg-main-color) !important;
  pointer-events: none !important;

  .btn__spinner {
    display: block;
  }

  .BtnTxt {
    display: none;
  }
}
