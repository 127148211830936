.Biz_CustomDataTable {
  border: 1px solid #8198b4;
  overflow: auto !important;
  background-color: #fff;
  position: relative;
  overflow: auto !important;
}

.Biz__CustomDataTable_Dash {
  display: grid;
  grid-template-columns: minmax(auto, 100%) minmax(0, max-content);
  grid-template-rows: auto;
  grid-row-gap: 12px;
  row-gap: 12px;
  margin: 8px;
}

.Biz_CustomDataTable {
  table {
    border-spacing: 0px;
    width: 100%;
    height: max-content !important;
    overflow: auto !important;
  }

  thead th {
    background: #8198b4;
    color: var(--Main-light-text-color);
    border-right: 1px solid #d6dbdf;
    font-size: 12px;
    padding: 3px 10px;
    text-align: left;
    font-weight: 400;
    position: sticky;
    top: 0px;
    white-space: nowrap;
    z-index: 1;

    &:last-child {
      border-right: 0px !important;
    }
  }

  tbody {
    td {
      &:last-child {
        border-right: 0px !important;
      }

      font-size: 12px;
      padding: 4px 10px;
      text-align: left;
      font-weight: 400;
      color: var(--Main-Dark-text-color);
      border-right: 1px solid #d6dbdf;
      border-bottom: 1px solid #d6dbdf;

      .Biz_DataTableIcon {
        font-size: 18px;
        display: flex;
        color: #374759;
      }

      .Biz_SmlDataTableIcon {
        font-size: 14px;
        display: flex;
        color: #374759;
      }
    }

    tr {
      cursor: pointer;

      &:hover {
        background-color: #fcf3cf !important;
      }

      &:nth-child(even) {
        background-color: #f3f6f9;
      }

      &:nth-child(odd) {
        background-color: #fff;
      }
    }
  }
}
