.DynBiz_ListDetail {
  padding-bottom: 34px;
  overflow: auto;
  display: grid;
}

.DynBiz_ListDetailDash {
  display: grid;
  grid-template-columns: 36px 290px auto;
  height: 100%;
  padding: 1px 0px;
}

.DynBiz_ListDetailFilters {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  overflow: auto;
  border-right: 1px solid #808b96;
  background-color: #d6dbdf;
}

.DynBiz_ListDetail__AlfFilters {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  overflow: auto;
}

.DynBiz_ListDetail__alpfilBanner {
  padding: 8px 0px !important;
  border-bottom: 1px solid #abb2b9;
  cursor: pointer;
  color: var(--lg-dark-textcolor);
  text-align: center;

  &:hover {
    background-color: var(--Main-Secondary-color) !important;
  }
}

.DynBiz_ListDetail__ActivealpfilBanner {
  background-color: var(--Main-Secondary-color) !important;

  h2 {
    color: #fff !important;
  }
}

.DynBiz_ListDetail__alpfilBanner {
  &:hover h2 {
    color: #fff !important;
  }

  h2 {
    font-size: 14px !important;
    font-weight: 400;
    user-select: none;
    color: #3f4254;
  }
}

.DynBiz_ListDetailContent_Div {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #fff;
  border-right: 1px solid #d6dbdf;
}

.DynBiz_ListDetailContent {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  overflow: auto;
  border-top: 1px solid #dddde8;
  width: 100%;
  min-width: 280px;
  background-color: var(--lg-light-backcolor);
  position: relative;
}

.DynBiz_ListDetailContentBox {
  padding: 6px 12px 12px 12px;
  position: relative;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  margin: 0px 0rem !important;

  &:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    background-color: #fdb660;

    h2,
    p {
      color: #fff !important;
    }
  }
}

.DynBiz_ActiveListDetailContentBox {
  h2,
  p {
    color: #fff !important;
  }

  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  background-color: #fdb660;
  color: #fff;
}

.DynBiz_ActiveListDetailInActiveContentBox {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  border-left: 5px solid red;
}

.DynBiz_ListDetailContentBox {
  h2 {
    font-size: 12px;
    color: #3f4254;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin: 5px 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 250px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  p {
    font-size: 12px;
    color: #b5b5c3;
    font-weight: normal;
    margin: 3px 0px;
  }
}

.DynBiz_ListDetailDiv {
  display: grid;
  grid-template-rows: max-content max-content auto;
  padding: 0px 0px 8px 0px !important;
  row-gap: 12px;
  overflow: auto;
  position: relative;

  &.DynBiz_ListDetailDivNotActive {
    * {
      pointer-events: none !important;
    }

    .DynBiz_ActiveBtn {
      pointer-events: all !important;
    }
  }
}

.DynBiz_ListDetail__Pagetitle {
  background-color: transparent;
  display: grid;
  grid-template-columns: auto max-content;
  padding: 7px;
  margin: 0px 8px -10px 8px;
  align-items: center;
  border: 1px solid transparent;
  z-index: 0;

  span {
    font-size: 13px;
    color: #23374d;
    font-weight: 500;
  }
}

.DynBiz_ListDetail__Pagetitle__box {
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
}

.DynBiz_ListDetail_Error {
  border-width: 1px;
  font-weight: 500;
  font-size: 12px;
  color: red;
  font-family: "Poppins", sans-serif;
  padding: 0px 50px;
  width: max-content;
}

.DynBiz_ListDetail__Pagetitle_btn {
  padding: 2px 10px;
  height: 26px;
  font-size: 11px;
  border: none;
  border-radius: 3px;
  color: var(--Main-light-text-color);
  cursor: pointer;
  grid-column-gap: 8px;
  display: grid;
  grid-template-columns: max-content;
  align-items: center;

  svg {
    font-size: 14px;
  }

  p {
    color: var(--Main-light-text-color);
    text-transform: uppercase;
  }
}

.DynBiz_ListDetail__Pagetitle_btnSecondary {
  background-color: var(--Main-Primary-Color) !important;
}

.DynBiz_ListDetail__Pagetitle_btnGreen {
  background-color: var(--Main-Secondary-color) !important;
}

.DynBiz_ListDetail__Pagetitle_btnRed {
  background-color: #ff365b;
}

.DynBiz_ListDetail__MainDivBox {
  display: flex;
  flex-direction: row;
  background-color: var(--lg-light-backcolor);
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

.DynBiz_ListDetail__DetailBox {
  border-radius: 0.18rem;
  display: grid;
  grid-template-columns: auto max-content max-content;
  column-gap: 12px;
  margin: 0px 8px;
  overflow: auto;
}

.DynBiz_ListDetail__DetailTxt {
  display: grid;
  row-gap: 8px;
  padding: 12px;
  background-color: var(--lg-light-backcolor);
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  border: 1px solid #d6dbdf;
  grid-template-columns: max-content minmax(max-content, 420px);
  grid-column-gap: 12px;
}

.DynBiz_ListDetail__DContentBox,
.DynBiz_ListDetail__DetailsCBox {
  display: contents;
}

.DynBiz_ListDetail__CummunicationContent {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 12px;
  row-gap: 5px;
  row-gap: 5px;
  white-space: nowrap;
}

.DynBiz_ListDetail__DetailsCBox {
  label {
    font-size: 12px;
    font-weight: 500;
    color: #363a3e;
    font-family: "Poppins", sans-serif;
    user-select: none;

    &:after {
      content: ":";
      padding-left: 6px;
    }
  }

  p {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #363a3e;
    padding-left: 6px;
  }
}

.DynBiz_ListDetail__TaxDetailsCBox {
  display: contents;
}

.DynBiz_ListDetail__TaxContent {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 12px;
  row-gap: 5px;
}

.DynBiz_ListDetail__TaxDetailsCBox {
  label {
    font-size: 12px;
    font-weight: 500;
    color: #363a3e;
    font-family: "Poppins", sans-serif;
    user-select: none;

    &:after {
      content: ":";
      padding-left: 6px;
    }
  }

  p {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #363a3e;
    padding-left: 6px;
  }
}

.DynBiz_ListDetail__DContentBox {
  label {
    font-size: 12px;
    font-weight: 600;
    color: #666d75;
    font-family: "Poppins", sans-serif;
    user-select: none;

    &:after {
      content: ":";
      padding-left: 6px;
    }
  }

  p {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}

.DynBiz_ListDetail__CummunicationDetails {
  border: 1px solid #d6dbdf;
  border-top: 0px !important;
}

.DynBiz_ListDetail__CummunicationTitle {
  background-color: var(--Main-Footer-Color) !important;
  color: #fff;
  padding: 2px 0px;
  display: grid;
  font-size: 12px;
  justify-content: center;
  min-width: 260px;
  user-select: none;
  font-family: "Poppins", sans-serif;
}

.DynBiz_ListDetail__CummunicationContent {
  padding: 8px;
}

.DynBiz_ListDetail__TaxDetails {
  border: 1px solid #d6dbdf;
  border-top: 0px !important;
}

.DynBiz_ListDetail__TaxTitle {
  background-color: var(--Main-Footer-Color) !important;
  color: #fff;
  padding: 2px 0px;
  display: grid;
  font-size: 12px;
  justify-content: center;
  min-width: 260px;
  user-select: none;
  font-family: "Poppins", sans-serif;
}

.DynBiz_ListDetail__TaxContent {
  padding: 8px;
}

.DynBiz_ListDetail__MultiDetailBox {
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  background-color: var(--lg-light-backcolor);
  display: grid;
  grid-template-rows: max-content auto;
  padding: 8px;
  margin: 0px 8px;
  height: 100%;
  overflow: auto;
  border: 1px solid #d6dbdf;
}

.DynBiz_ListDetail__MDB_BtnPanel {
  border-bottom: 0px;
}

.DynBiz_ListDetail__MDb_TabPanel {
  display: flex;
  flex-direction: row;
}

.DynBiz_ListDetail__MDb_TabList {
  background: #eeedf0;
  font-size: 14px;
  color: var(--Main-Dark-text-color);
  border: 1px solid #dedede;
  border-bottom: none !important;
  list-style: none;
  display: grid;
  align-items: center;
  padding: 8px 12px;
  margin-right: 4px;
  cursor: pointer;
  user-select: none !important;
  border-top-left-radius: 0.18rem;
  border-top-right-radius: 0.18rem;
  white-space: nowrap;

  &:hover {
    background: var(--Main-Secondary-color);
    color: var(--Main-light-text-color) !important;
  }
}

.Active__MDb_TabList {
  background: var(--Main-Secondary-color);
  color: var(--Main-light-text-color) !important;
  border: 1px solid var(--Main-Secondary-color);
  border-left: none !important;
}

.DynBiz_ListDetail__MDB_ContentBox {
  border: 1px solid #d6dbdf;
  height: 100%;
  display: grid;
  gap: 10px;
  overflow-y: auto;
}

.DynBiz_ListDetail__MDB_ContentDiv {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, max-content));
  grid-auto-rows: max-content;
  gap: 20px;
}

.DynBiz_ListDetail__MDB_ContentMDiv {
  display: grid;
  padding: 12px;
  height: 100%;
  width: 100%;
}

.Active__MDb_TabListdddd {
  border: 1px solid #eee;
}

.DynBiz_ListDetail__Footer {
  background-color: var(--Main-Footer-Color);
  height: 34px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  border-top: 1px solid #ccc;
  display: grid;
  grid-template-columns: max-content auto;
  gap: 12px;
  align-items: center;
}

.DynBiz_ListDetail__FooterDiv {
  padding: 0px 10px;
  display: inline-flex;
  align-items: center;

  &:nth-child(2) {
    justify-content: flex-end;
  }
}

.DynBiz_ListDetail_Footer__Functionbtn {
  height: 24px;
  width: 26px;
  color: var(--Main-Dark-text-color);
  background-color: #ebedef;
  border: none;
  outline: 0 !important;
  font-size: 0.8rem;
  border-radius: 0.22rem;
  margin-right: 0.5rem !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:last-child {
    margin: 0px !important;
  }

  &:hover {
    background-color: #fff;
  }
}

.DynBiz_ListDetail__FormSearchDiv {
  position: relative;
  display: grid;
  grid-template-columns: auto max-content max-content;
  align-items: center;
}

.DynBiz_ListDetailContent_SearchBox {
  padding: 0px 0px 0px 6px;
  border-bottom: 3px solid #3d8bda;
}

.DynBiz_ListDetail__FormSearchBox {
  width: 100%;
  font-size: 12px;
  height: 30px;
  padding: 6px 8px 6px 8px;
  margin: auto;
  border-radius: 2px;
  border: none;
  background-color: transparent;
  outline: none;
  color: var(--lg-dark-textcolor);
}

.DynBiz_ListDetail__FormSearchIcon {
  &:hover {
    opacity: 0.8;
  }

  right: 0px;
  padding: 7px 16px;
  font-size: 12px;
  color: var(--Main-light-text-color);
  cursor: pointer;
  background-image: linear-gradient(var(--Main-Primary-Color), #3d8bda);
  height: 32px;
}

.DynBiz_ListDetail__CanceSearchlIcon {
  padding: 5px 7px 2px 7px;
  font-size: 16px;
  display: grid;
  border-radius: 2px;
  color: var(--Main-light-text-color);
  cursor: pointer;
  background: #ff365b;
  height: 25px;

  &:hover {
    opacity: 0.8;
  }
}

.DynBiz_ListDetail__MDB_AddContBox {
  border: 0;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  border-top: none !important;
  border: 1px solid #ebedef;
  background-clip: border-box;
}

.PrimaryCustomer__MDB_AddContBox .DynBiz_ListDetail__MDB_AddContBox_Header,
.DynBiz_ListDetail__MDB_AddContBox:nth-child(1)
  .DynBiz_ListDetail__MDB_AddContBox_Header {
  background-color: #fdb660;
}

.DynBiz_ListDetail__MDB_AddContBox_Header {
  background-color: #9aa5b6;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 10px;

  h4 {
    color: #fff;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
  }

  .Biz_Icon {
    color: var(--lg-light-backcolor);
    height: 20px;
    padding: 0px 5px;
    font-size: 30px;
    cursor: pointer;
    border-radius: 6px;
  }
}

.DynBiz_ListDetail__MDB_AddContBox_Body {
  padding: 12px 16px;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 4px;

  p {
    color: var(--lg-greydark-textcolor);
    font-size: 12px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}

.DynBiz_ListDetail_User_CBox {
  background-color: #fff;
  display: flex;
  align-items: stretch !important;
  flex-direction: column;
  border: 1px solid #d5d8dc;
  padding: 10px 14px 14px 14px;
  border-radius: 0.18rem;
  position: relative;
}

.DynBiz_ListDetail_User_MCbox {
  justify-content: flex-end !important;
  display: flex !important;
  height: 24px;
  cursor: pointer;
}

.DynBiz_ListDetail_UserTxt1 {
  font-size: 16px !important;
  color: var(--Main-Dark-text-color);
  font-weight: 500 !important;
  margin-bottom: 0px;
  font-family: Poppins, Helvetica, "sans-serif";
  white-space: nowrap;
}

.DynBiz_ListDetail_UserTxt2 {
  font-size: 11px !important;
  color: #b5b5c3 !important;
  font-family: Poppins, Helvetica, "sans-serif";
}

.DynBiz_ListDetail_User_BoxP1 {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 1rem !important;
}

.DynBiz_ListDetail_User_BoxP2 {
  display: flex !important;
  flex-direction: column;
  width: 100%;
}

.DynBiz_ListDetail_User_CNBox {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;

  label {
    font-size: 12px;
    color: var(--Main-Dark-text-color);
    font-weight: 500 !important;
    padding-right: 16px;
    font-family: Poppins, Helvetica, "sans-serif";

    &::after {
      content: ":";
      padding-left: 4px;
    }
  }

  p {
    font-size: 12px;
    color: #b5b5c3 !important;
    font-weight: 400 !important;
    font-family: Poppins, Helvetica, "sans-serif";
  }
}

.DynBiz_ListDetail_UserImgBox {
  margin-right: 1rem !important;
}

.DynBiz_ListDetail_UserImgSymbol {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Biz_UserSymbolImg {
  width: 66px;
  height: 66px;
}

.DynBiz_ListDetail_UserTxtBox {
  display: flex !important;
  flex-direction: column !important;
}

.DynBiz_ListDetail_User_setbar {
  position: absolute;
  z-index: 9999;
  top: 34px;
  right: 1px;
  width: 180px;
  background: #fff;
  padding: 6px;
  box-shadow: 1px 1px 5px #c1c1c1;
}

.DynBiz_ListDetail_Address_setbar {
  position: absolute;
  z-index: 9999;
  top: 30px;
  right: 1px;
  width: 180px;
  background: #fff;
  padding: 6px;
  box-shadow: 1px 1px 5px #c1c1c1;
}

.DynBiz_ListDetail_User_MCbox .Biz_Icon {
  font-size: 30px;
  color: var(--Main-light-text-color);
  padding: 0px 4px;
  border-radius: 0.18rem;
  height: 24px;
  background-color: var(--Sub-Header-Color);
}

.DynBiz_ListDetail__MDB_ContentDiv {
  display: none;
}

.ActiveCustomer__MDB_ContentDiv {
  display: grid;
}

.DynBiz_ListDetail_BankContBox {
  border: 0;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  border-top: none !important;
  border: 1px solid #ebedef;
  background-clip: border-box;

  &:nth-child(1) .DynBiz_ListDetail_BankContBox_Header {
    background-color: #fdb660;
  }
}

.DynBiz_ListDetail_BankContBox_Header {
  background-color: #abb2b9;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 10px;

  h4 {
    color: #fff;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
  }

  .Biz_Icon {
    color: var(--lg-light-backcolor);
    height: 20px;
    padding: 0px 5px;
    font-size: 30px;
    cursor: pointer;
    border-radius: 6px;
  }
}

.DynBiz_ListDetail_Bank_setbar {
  position: absolute;
  z-index: 9999;
  top: 30px;
  right: 1px;
  width: 180px;
  background: #fff;
  padding: 6px;
  box-shadow: 1px 1px 5px #c1c1c1;
}

.DynBiz_ListDetail_Bank_BoxP2 {
  display: flex !important;
  flex-direction: column;
  width: 100%;
}

.DynBiz_ListDetail_BankContBox_Body {
  padding: 5px 10px 0px 10px;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 4px;
}

.DynBiz_ListDetail_Bank_CNBox {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;

  label {
    font-size: 12px;
    color: var(--Main-Dark-text-color);
    font-weight: 500 !important;
    padding-right: 16px;
    font-family: Poppins, Helvetica, "sans-serif";

    &::after {
      content: ":";
      padding-left: 4px;
    }
  }

  p {
    font-size: 12px;
    color: #b5b5c3 !important;
    font-weight: 400 !important;
    font-family: Poppins, Helvetica, "sans-serif";
  }
}

.DynBiz_ListDetail_Empty_dataBox {
  margin-top: 50px;
  display: grid;
  align-items: center;
  justify-content: center;

  img {
    height: 80px;
    display: flex;
    margin: 0 auto;
  }

  p {
    font-size: 14px;
    margin-top: 12px;
    color: #505050;
    font-family: "Poppins", sans-serif;
  }
}
