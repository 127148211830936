[Biz-toltp] {
  position: relative;
  z-index: 999;

  &:before,
  &:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    left: 50%;
    bottom: calc(100% + 5px);
    pointer-events: none;
    will-change: transform;
  }

  &:before {
    content: attr(Biz-toltp);
    padding: 4px 8px;
    min-width: 40px;
    max-width: 300px;
    width: max-content;
    width: -moz-max-content;
    border-radius: 6px;
    font-size: 10px;
    color: #3f4254;
    background-color: #ffffff;
    text-align: center;
    white-space: pre-wrap;
    transform: translate(-50%, -5px) scale(0.5);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  }

  &:after {
    content: "";
    border-style: solid;
    border-width: 6px 6px 0px 6px;
    border-color: #fff transparent transparent transparent;
    transition-duration: 0s;
    transform-origin: top;
    transform: translateX(-50%) scaleY(0);
  }

  &:hover {
    &:before,
    &:after {
      visibility: visible;
      opacity: 1;
    }

    &:before {
      transform: translate(-50%, -5px) scale(1);
    }

    &:after {
      transform: translateX(-50%) scaleY(1);
    }
  }
}

[Biz-toltp-modifier="left"] {
  &:before,
  &:after {
    left: auto;
    right: calc(100% + 2px);
    bottom: 50%;
  }

  &:before {
    transform: translate(-5px, 50%) scale(0.5);
  }

  &:hover:before {
    transform: translate(-5px, 50%) scale(1);
  }

  &:after {
    border-width: 6px 0px 6px 6px;
    border-color: transparent transparent transparent #fff;
    transform-origin: left;
    transform: translateY(50%) scaleX(0);
  }

  &:hover:after {
    transform: translateY(50%) scaleX(1);
  }
}

[Biz-toltp-modifier="right"] {
  &:before,
  &:after {
    left: calc(100% + 2px);
    bottom: 50%;
  }

  &:before {
    transform: translate(5px, 50%) scale(0.5);
  }

  &:hover:before {
    transform: translate(5px, 50%) scale(1);
  }

  &:after {
    border-width: 5px 5px 5px 0px;
    border-color: transparent #fff transparent transparent;
    transform-origin: right;
    transform: translateY(50%) scaleX(0);
  }

  &:hover:after {
    transform: translateY(50%) scaleX(1);
  }
}

[Biz-toltp-modifier="bottom"] {
  &:before,
  &:after {
    top: calc(100% + 2px);
    bottom: auto;
  }

  &:before {
    transform: translate(-50%, 5px) scale(0.5);
  }

  &:hover:before {
    transform: translate(-50%, 5px) scale(1);
  }

  &:after {
    border-width: 0px 5px 5px 5px;
    border-color: transparent transparent #fff transparent;
    transform-origin: bottom;
  }
}

@media (max-width: 750px) {
  [Biz-toltp]:after {
    bottom: calc(100% + 3px);
    border-width: 8px 8px 0px 8px;
  }
}
