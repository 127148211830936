.Div {
  background-color: var(--Main-Footer-Color);
  height: 34px;
  width: 100%;
  position: fixed;
  bottom: 0;
  box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
  border-top: 1px solid #ccc;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 12px;
  gap: 12px;
  align-items: center;
  z-index: 10;

  .BoxRight {
    justify-content: flex-end !important;
  }

  > .Box {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &:nth-child(2) {
      justify-content: flex-end;
    }

    .Btn {
      height: 24px;
      color: var(--Main-Dark-text-color);
      background-color: #ebedef;
      border: none;
      outline: 0 !important;
      font-size: 0.8rem;
      border-radius: 0.22rem;
      padding: 0px 12px;
      font-weight: 500;
      margin-right: 0.5rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &:last-child {
        margin: 0px !important;
      }

      &:hover {
        background-color: #fff;
      }
    }
  }
}
