@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  outline: none;
}

body {
  overflow: hidden;
}

img,
p,
span,
a,
link {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* --------------- Button CSS --------------- */

button {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  cursor: pointer;

  &:active {
    transform: scale(0.96);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.08);
  }
}

.rdrDay:active {
  transform: none !important;
  box-shadow: none !important;
}

.rdrMonthAndYearWrapper {
  padding-top: 0px !important;
}

/* --------------- Button CSS --------------- */

/* --------------- Layout CSS --------------- */

.Biz__PageStart {
  padding-top: 100px;
  background-color: var(--Main-Page-Background);
  height: 100vh;
  width: 100vw;
  position: relative;
}

.Biz__RPageStart {
  padding-top: 100px;
  background-color: var(--Main-Page-Background);
  height: 100vh;
  width: 100vw;
  display: grid;
  position: relative;
  grid-template-rows: max-content auto;
}

.Biz__MPageStart {
  padding-top: 100px;
  background-color: var(--Main-Page-Background);
  height: 100vh;
  width: 100vw;
  display: grid;
  position: relative;
  grid-template-rows: auto 35px;
}

.Biz_Listing_PageStart {
  padding-top: 100px;
  background-color: var(--Main-Page-Background);
  height: 100vh;
  width: 100vw;
  position: relative;
  display: grid;
  grid-template-rows: max-content auto 35px;
}

.Biz_DualListing_PageStart {
  padding-top: 100px;
  background-color: var(--Main-Page-Background);
  height: 100vh;
  width: 100vw;
  position: relative;
  display: grid;
  grid-template-rows: auto 34px;
}

/* --------------- Layout CSS --------------- */

/* --------------- ScrollBar CSS --------------- */

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-track {
  background: #eaecee;
}

::-webkit-scrollbar-thumb {
  background: var(--Main-scrollbar-color);
  border-radius: 1px;
  border: 1px solid #fff;

  &:hover {
    background-color: #85929e;
  }
}

.rdrInfiniteMonths::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

/* --------------- ScrollBar CSS --------------- */

/* --------------- Input Box CSS --------------- */

::placeholder {
  color: #808b96;
  font-size: 12px;
  opacity: 1;
}

input::placeholder,
.css-sgf11h-placeholder {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* --------------- Input Box CSS --------------- */

/* --------------- Calender CSS --------------- */

.rdrMonth {
  width: 100% !important;
  padding: 0px !important;
}

.rdrMonthsVertical {
  width: 100% !important;
}

.rdrMonthAndYearWrapper {
  height: 40px !important;
}

.rdrMonthAndYearPickers select {
  font-size: 12px !important;
  padding-left: 0px !important;
  padding-right: 22px !important;
}

.rdrNextPrevButton {
  margin-left: 7px !important;
}

.rdrNextButton {
  margin-right: 7px !important;
}

.rdrDay {
  line-height: 2.8em !important;
}

.rdrWeekDay {
  font-size: 12px !important;
}

.rdrWeekDays {
  padding: 0px 3px !important;
}

.rdrMonthName {
  padding-top: 0px !important;
}

.rdrNextPrevButton {
  border: 1px solid #a7aeb2 !important;
  background-color: #fff !important;
  border-radius: 8px !important;
  width: 26px !important;
}

.rdrPprevButton i {
  border-color: transparent #a7aeb2 transparent transparent !important;
}

.rdrNextButton i {
  border-color: transparent transparent transparent #a7aeb2 !important;
}

.rdrSelected {
  border-radius: 2px !important;
}

.rdrDayHovered .rdrDayEndPreview + .rdrDayNumber {
  background-color: #eee !important;
}

.rdrDayHovered .rdrSelected + .rdrDayEndPreview + .rdrDayNumber {
  background-color: transparent !important;
}

.rdrDayNumber::after,
.rdrSelected + .rdrDayEndPreview,
.rdrDayEndPreview {
  color: transparent !important;
}
/* --------------- Calender CSS --------------- */

.css-sgf11h-placeholder {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.dataGrid-header-centered {
  justify-content: center !important;
}

.DynBiz_ContextMenu {
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 6px 16px);
  min-width: 180px;
  background: #fff;
  position: absolute;
  top: 25px;
  right: 0px;
  border-radius: 4px;
  padding: 8px 9px 8px 8px;
  z-index: 1;
  white-space: nowrap;
  display: grid;
  grid-auto-rows: max-content;
  transform: scale(1);
  transition: transform 50ms ease-in-out;
  transform-origin: top left;
  border: 2px solid #ebebed;
}

.DynBiz_ContextMenu_list {
  display: grid;
  grid-template-columns: 20px auto;
  grid-template-rows: 20px;
  align-items: center;
  column-gap: 6px;
  margin-bottom: 5px;
  padding: 4px 7px 6px 7px;
  width: 100%;
  background: transparent;
  border: none;
  transform: none !important;
  box-shadow: none !important;
  border-radius: 3px;
  svg {
    font-size: 20px;
    color: #8a909b;
  }
  span {
    color: #404455;
    text-align: left;
    margin-top: 3px;
    font-size: 13px;
    padding-right: 2px;
  }
  &:hover {
    background: #f0f1f3;
    svg {
      color: #5a6474 !important;
    }
  }
  &.dRed {
    &:hover {
      background: #ffecee;
    }
  }
  &:last-child {
    margin-bottom: 0px !important;
  }
}

.DynBiz_ContextMenu_lined {
  background: #e4e4e4 !important;
  margin-bottom: 5px;
  height: 1px;
  margin-left: -9px;
  margin-right: -10px;
}

.DynBiz_ContextMenu_list.dRed:hover svg,
.DynBiz_ContextMenu_list.dRed:hover span {
  color: #fe3e5a !important;
  opacity: 1 !important;
}

.BudgetPlanningGrid {
  display: grid;

  .ag-theme-blue .ag-row-selected {
    background: #ffffff !important;
  }

  .ag-theme-blue .ag-ltr .ag-cell-range-single-cell {
    border: 1px solid #ffffff !important;
  }

  .BizN____control--is-focused {
    border-width: 2px !important;
  }
}

.row-warning {
  background-color: sandybrown !important;
}
