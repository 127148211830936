.BoxGroup {
  background: #eef7fd;
  border: 1px solid #adcffc;

  .BoxGroupTxt {
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    color: #549cf8;
    line-height: 1.5;
    text-transform: capitalize;
    width: 100.1%;
    margin-top: -11px;
    user-select: none !important;
    -webkit-user-drag: none !important;

    &:after,
    &:before {
      content: "";
      border-top: 1px solid #adcffc;
      flex: 1 0 0px;
    }

    &:before {
      margin-right: 6px;
    }

    &:after {
      margin-left: 6px;
    }
  }
}

.InputGroupBox {
  display: contents;

  .InputLabel {
    font-size: 13px;
    line-height: 1.5;
    text-transform: capitalize;
    font-weight: 400;
    color: #555;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 32px auto;
    align-items: center;
    white-space: nowrap;
    user-select: none !important;
    -webkit-user-drag: none !important;

    &.InputReqLabel {
      text-decoration: 2px underline #f64e60 !important;
    }
  }

  .InputGroup {
    display: grid;
    grid-template-rows: minmax(32px, max-content) auto;
    grid-template-columns: auto max-content;
    grid-row-gap: 2px;
    row-gap: 2px;
    height: 100%;
    align-items: center;
    position: relative;

    .InputControl {
      width: 100%;
      height: 100%;
      border: 1px solid #d6dbdf;
      padding: 6px 8px;
      border-radius: 0 !important;
      outline: none;
      font-size: 12px;
      color: #3f4254;
      font-weight: 400;
      height: 28px;

      &:focus {
        border: 1px solid #0071e3 !important;
        box-shadow: 0 0 0 2px rgba(0, 125, 250, 0.3);
      }

      &::placeholder {
        font-size: 12px;
        color: #3f4254;
        font-weight: 400;
      }

      &:disabled,
      &[readonly] {
        background-color: #e9ecef;
        opacity: 0.8;
        resize: none !important;
        cursor: not-allowed !important;
      }
    }
  }

  &.InputErrorMsgBox {
    .ErrorMsg {
      color: #f64e60;
      font-size: 11px;
      font-weight: 500;
      grid-column: 1;
      grid-row: 2;
    }

    .InputControl,
    > div > div > div,
    .Switch_label {
      border: 1px solid #f64e60 !important;
      box-shadow: none !important;

      &:focus {
        border: 1px solid #f64e60 !important;
      }
    }
  }

  &.ControlRowSpan {
    .InputControl {
      height: 100% !important;
    }
  }
}

.Switch {
  position: relative;
  display: block;
  width: 56px;
  height: 24px;
  margin: auto 0 12px 0;
  border-radius: 18px;
  cursor: pointer;
  box-sizing: content-box;

  .Switch_input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-sizing: content-box;
  }

  .Switch_label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 11px;
    height: 26px;
    text-transform: uppercase;
    background: #adb5bd;
    border-radius: inherit;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12),
      inset 0 0 2px rgba(0, 0, 0, 0.15);
    box-sizing: content-box;
    border: 1px solid transparent;

    &:before,
    &:after {
      position: absolute;
      top: 52%;
      margin-top: -0.5em;
      line-height: 1;
      -webkit-transition: inherit;
      -moz-transition: inherit;
      -o-transition: inherit;
      transition: inherit;
      box-sizing: content-box;
    }

    &:before {
      content: "No";
      right: 8px;
      color: #fff;
      font-weight: 500;
    }

    &:after {
      content: "Yes";
      left: 7px;
      color: #ffffff;
      text-shadow: 0 1px rgba(0, 0, 0, 0.2);
      opacity: 0;
    }
  }

  .Switch_input:checked ~ .Switch_label {
    background: linear-gradient(90deg, #4f97ff, #275efe);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
      inset 0 0 3px rgba(0, 0, 0, 0.2);

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }

  .Switch_handle {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 1px;
    margin: auto 0px;
    width: 42%;
    height: 92%;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
  }

  .Switch_input:checked ~ .Switch_handle {
    left: 28px !important;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
  }

  .Switch_label,
  .Switch_handle {
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
  }
}
