.Biz_Bm_Header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 97;
  height: 108px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-bottom: 1px solid #d9e3eb;
}

.Biz_Bm_Header__MainBar {
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-template-rows: 54px;
  position: relative;
  background-color: #6f7e95;
}

.Biz_Bm_Header__SubBar {
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: 54px;
  position: relative;
  padding-left: 120px;
  padding-right: 12px;
}

.Header__ClientLogoBar {
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  min-width: 220px;
}

.Header__ClientLogoBar .Biz__Icon,
.Header__ClientLogoBar img,
.Header__ClientLogoBar a {
  height: 32px;
  cursor: pointer;
}

.Header__ClientLogoBar a {
  background: rgba(255, 255, 255, 0.96);
  padding: 4px 8px;
  height: 40px;
  border-radius: 1px;
}

.Biz_Bm_Header,
.Biz_Bm_Header ul,
.Biz_Bm_Header ul li,
.Biz_Bm_Header ul li a {
  list-style: none;
}

.Header__MainMenuBar {
  display: -webkit-inline-flex;
  display: inline-flex;
  overflow-y: hidden;
  overflow-x: auto;
  align-items: flex-end;
}

.Header__UlModule {
  height: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  overflow-y: hidden;
  overflow-x: auto;
  height: 40px;
}

.Biz_Bm_Header ul li {
  padding: 0;
  margin-right: 10px;
  position: relative;
  border-radius: 0.42rem 0.42rem 0px 0px;
  display: grid;
  cursor: pointer;
}

.Biz_Bm_Header ul li .Header__MenuTxt {
  text-decoration: none;
  padding: 0px 1rem;
  font-weight: 500;
  color: #fff;
  opacity: 0.9;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  white-space: nowrap;
}

.Biz_Bm_Header ul .Header_ActiveMMenu,
.Biz_Bm_Header ul li:hover {
  background-color: #fff;
}

.Biz_Bm_Header ul .Header_ActiveMMenu .Header__MenuTxt,
.Biz_Bm_Header ul li:hover .Header__MenuTxt {
  color: #283747;
}

.SubMenu__UlModule {
  height: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  overflow-y: hidden;
  overflow-x: auto;
  align-items: center;
}

.SubMenu__UlModule .SubHeader_MMenu {
  height: 32px;
  padding: 0;
  position: relative;
  display: grid;
  cursor: pointer;
  border-radius: 0.42rem;
  padding: 0px 1rem;
  text-decoration: none;
}

.SubMenu__UlModule .SubHeader_MMenu:hover .Header__MenuTxt {
  color: #01c4dc !important;
  height: 40px;
}

.SubMenu__UlModule .SubHeader_MMenu .Header__MenuTxt,
.SubMenu__UlModule .SubHeader_ActiveMMenu:hover .Header__MenuTxt {
  color: #34495e !important;
  height: 32px !important;
  line-height: 32px !important;
  font-size: 13px;
}

.SubMenu__UlModule .SubHeader_ActiveMMenu {
  background-color: #ebedef;
}

.SubMenu__UlModule .SubHeader_ActiveMMenu .Header__MenuTxt {
  color: #34495e !important;
}

.Btn_Logout {
  padding: 2px 10px;
  margin: 6px 10px;
  font-size: 15px;
  font-weight: 400;
  align-items: center;
  border: 0;
  height: 40px;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: #e81010;
  color: white;
}

.Btn_Logout:hover {
  cursor: pointer;
  opacity: 0.8;
}
