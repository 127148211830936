.Biz__Customer {
  padding-bottom: 34px;
}

.Biz__CustomerDash {
  display: grid;
  grid-template-columns: 290px auto;
  height: 100%;
  padding: 1px 0px;
  overflow: auto;
}

.Biz__CustomerFilters {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  overflow: auto;
  border-right: 1px solid #808b96;
  background-color: #d6dbdf;
}

.Biz__Customer_AddPopup_FormContentPt3 {
  position: relative;
}

.Customer__AlfFilters {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  overflow: auto;
}

.Customer__alpfilBanner {
  padding: 8px 0px !important;
  border-bottom: 1px solid #abb2b9;
  cursor: pointer;
  color: var(--lg-dark-textcolor);
  text-align: center;

  &:hover {
    background-color: var(--Main-Secondary-color) !important;
  }
}

.Customer__Activealpfil {
  background-color: var(--Main-Secondary-color) !important;

  h2 {
    color: #fff !important;
  }
}

.Customer__alpfilBanner {
  &:hover h2 {
    color: #fff !important;
  }

  h2 {
    font-size: 14px !important;
    font-weight: 400;
    user-select: none;
    color: #3f4254;
  }
}

.Biz__CustomerList_Div {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #fff;
  border-right: 1px solid #d6dbdf;
}

.Biz__CustomerList {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  overflow: auto;
  border-top: 1px solid #dddde8;
  width: 100%;
  min-width: 280px;
  background-color: var(--lg-light-backcolor);
  position: relative;
}

.Biz__CustomerListBox {
  padding: 6px 12px 12px 12px;
  position: relative;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  margin: 0px 0rem !important;

  &:hover {
    transition: all 0.15s ease;
    background-color: #fdb660;

    h2,
    p {
      color: #fff !important;
    }
  }
}

.Active__CustomerListBox {
  h2,
  p {
    color: #fff !important;
  }

  transition: all 0.15s ease;
  background-color: #fdb660;
  color: #fff;
}

.Biz__CustomerListBox {
  h2 {
    font-size: 12px;
    color: #3f4254;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin: 5px 0;
    user-select: none;
    width: 250px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  p {
    font-size: 12px;
    color: #b5b5c3;
    font-weight: normal;
    margin: 3px 0px;
  }
}

.Biz__CustomerDiv {
  display: grid;
  grid-template-rows: max-content max-content auto;
  padding: 0px 0px 8px 0px !important;
  row-gap: 12px;
  overflow: auto;
  position: relative;
}

.Customer__Pagetitle {
  background-color: transparent;
  display: grid;
  grid-template-columns: auto max-content;
  padding: 7px;
  margin: 0px 8px -10px 8px;
  align-items: center;
  border: 1px solid transparent;
  z-index: 0;

  span {
    font-size: 13px;
    color: #23374d;
    font-weight: 500;
  }
}

.Customer__Pagetitle__box {
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
}

.Customer_Error {
  border-width: 1px;
  font-weight: 500;
  font-size: 12px;
  color: red;
  font-family: "Poppins", sans-serif;
  padding: 0px 50px;
  width: max-content;
}

.Customer__Pagetitle_btn {
  padding: 2px 10px;
  height: 26px;
  font-size: 11px;
  border: none;
  border-radius: 3px;
  color: var(--Main-light-text-color);
  cursor: pointer;
  grid-column-gap: 8px;
  display: grid;
  grid-template-columns: max-content;
  align-items: center;

  svg {
    font-size: 14px;
  }
}

.Customer__Pagetitle_btnSecd {
  background-color: var(--Main-Secondary-color);
}

.Customer__Pagetitle_btnRed {
  background-color: #ff365b;
}

.Customer__Pagetitle_btn {
  p {
    color: var(--Main-light-text-color);
    text-transform: uppercase;
  }

  &:hover {
    background-color: #5d6d7e;
  }
}

.Customer__MainDivBox {
  display: flex;
  flex-direction: row;
  background-color: var(--lg-light-backcolor);
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

.Customer__DetailBox {
  border-radius: 0.18rem;
  display: grid;
  grid-template-columns: auto max-content max-content;
  column-gap: 12px;
  margin: 0px 8px;
  overflow: auto;
}

.Customer__DetailTxt {
  display: grid;
  row-gap: 8px;
  padding: 12px;
  background-color: var(--lg-light-backcolor);
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  border: 1px solid #d6dbdf;
  grid-template-columns: max-content minmax(max-content, 420px);
  grid-column-gap: 12px;
}

.Customer__DContentBox,
.Customer__DetailsCBox {
  display: contents;
}

.Customer__CummunicationContent {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 12px;
  row-gap: 5px;
  row-gap: 5px;
  white-space: nowrap;
}

.Customer__DetailsCBox {
  label {
    font-size: 12px;
    font-weight: 500;
    color: #363a3e;
    font-family: "Poppins", sans-serif;
    user-select: none;

    &:after {
      content: ":";
      padding-left: 6px;
    }
  }

  p {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #363a3e;
    padding-left: 6px;
  }
}

.Customer__TaxDetailsCBox {
  display: contents;
}

.Customer__TaxContent {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 12px;
  row-gap: 5px;
}

.Customer__TaxDetailsCBox {
  label {
    font-size: 12px;
    font-weight: 500;
    color: #363a3e;
    font-family: "Poppins", sans-serif;
    user-select: none;

    &:after {
      content: ":";
      padding-left: 6px;
    }
  }

  p {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #363a3e;
    padding-left: 6px;
  }
}

.Customer__DContentBox {
  label {
    font-size: 12px;
    font-weight: 600;
    color: #666d75;
    font-family: "Poppins", sans-serif;
    user-select: none;

    &:after {
      content: ":";
      padding-left: 6px;
    }
  }

  p {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
}

.Customer__CummunicationDetails {
  border: 1px solid #d6dbdf;
  border-top: 0px !important;
}

.Customer__CummunicationTitle {
  background-color: var(--Main-Footer-Color) !important;
  color: #fff;
  padding: 2px 0px;
  display: grid;
  font-size: 12px;
  justify-content: center;
  min-width: 260px;
  user-select: none;
  font-family: "Poppins", sans-serif;
}

.Customer__CummunicationContent {
  padding: 8px;
}

.Customer__TaxDetails {
  border: 1px solid #d6dbdf;
  border-top: 0px !important;
}

.Customer__TaxTitle {
  background-color: var(--Main-Footer-Color) !important;
  color: #fff;
  padding: 2px 0px;
  display: grid;
  font-size: 12px;
  justify-content: center;
  min-width: 260px;
  user-select: none;
  font-family: "Poppins", sans-serif;
}

.Customer__TaxContent {
  padding: 8px;
}

.Customer__MultiDetailBox {
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  background-color: var(--lg-light-backcolor);
  display: grid;
  grid-template-rows: max-content auto;
  padding: 8px;
  margin: 0px 8px;
  height: 100%;
  overflow: auto;
  border: 1px solid #d6dbdf;
}

.Customer__MDB_BtnPanel {
  border-bottom: 0px;
  display: grid;
  grid-template-columns: auto max-content max-content;
  column-gap: 8px;
  align-items: center;
}

.Customer__MDb_TabPanel {
  display: flex;
  flex-direction: row;
}

.Customer__MDb_TabList {
  background: #eeedf0;
  font-size: 14px;
  color: var(--Main-Dark-text-color);
  border: 1px solid #dedede;
  border-bottom: none !important;
  list-style: none;
  display: grid;
  align-items: center;
  padding: 8px 12px;
  margin-right: 4px;
  cursor: pointer;
  user-select: none !important;
  border-top-left-radius: 0.18rem;
  border-top-right-radius: 0.18rem;
  white-space: nowrap;

  &:hover {
    background: var(--Main-Secondary-color);
    color: var(--Main-light-text-color) !important;
  }
}

.Active__MDb_TabList {
  background: var(--Main-Secondary-color);
  color: var(--Main-light-text-color) !important;
  border: 1px solid var(--Main-Secondary-color);
  border-left: none !important;
}

.Customer__MDB_ContentBox {
  border: 1px solid #d6dbdf;
  height: 100%;
  display: grid;
  gap: 10px;
  overflow-y: auto;
}

.Customer__MDB_ContentDiv {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, max-content));
  grid-auto-rows: max-content;
  gap: 20px;
}

.Customer__MDB_ContentMDiv {
  display: grid;
  padding: 12px;
  height: 100%;
  width: 100%;
}

.Active__MDb_TabListdddd {
  border: 1px solid #eee;
}

.Customer__Footer {
  background-color: var(--Main-Footer-Color);
  height: 34px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  border-top: 1px solid #ccc;
  display: grid;
  grid-template-columns: max-content auto;
  gap: 12px;
  align-items: center;
}

.Customer__FooterDiv {
  padding: 0px 10px;
  display: inline-flex;
  align-items: center;

  &:nth-child(2) {
    justify-content: flex-end;
  }
}

.Customer_Footer__Functionbtn {
  height: 24px;
  width: 26px;
  color: var(--Main-Dark-text-color);
  background-color: #ebedef;
  border: none;
  outline: 0 !important;
  font-size: 0.8rem;
  border-radius: 0.22rem;
  margin-right: 0.5rem !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:last-child {
    margin: 0px !important;
  }

  &:hover {
    background-color: #fff;
  }
}

.Customer__FormSearchDiv {
  position: relative;
  display: grid;
  grid-template-columns: auto max-content max-content;
  align-items: center;
}

.Biz__CustomerList_SearchBox {
  padding: 2px 6px;
  border-bottom: 3px solid var(--Main-Primary-Color);
}

.Customer__FormSearchBox {
  width: 100%;
  font-size: 12px;
  height: 30px;
  padding: 6px 10px;
  margin: auto;
  border-radius: 2px;
  border: none;
  background-color: transparent;
  outline: none;
  color: var(--lg-dark-textcolor);
}

.Customer__FormSearchIcon {
  &:hover {
    opacity: 0.8;
  }

  right: 0px;
  padding: 3px 8px 2px 8px;
  font-size: 14px;
  border-radius: 2px;
  margin: 2px 3px;
  color: var(--Main-light-text-color);
  cursor: pointer;
  background: var(--Main-Primary-Color);
  height: 24px;
}

.Customer__CanceSearchlIcon {
  padding: 4px 7px 2px 7px;
  font-size: 16px;
  display: grid;
  border-radius: 2px;
  color: var(--Main-light-text-color);
  cursor: pointer;
  background: #ff365b;
  height: 24px;

  &:hover {
    opacity: 0.8;
  }
}

.Customer__MDB_AddContBox {
  border: 0;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  border-top: none !important;
  border: 1px solid #ebedef;
  background-clip: border-box;
}

.PrimaryCustomer__MDB_AddContBox {
  background-color: #fdb660;
}

.Customer__MDB_AddContBox_Header {
  background-color: #9aa5b6;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 10px;

  h4 {
    color: #fff;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
  }

  .Biz_Icon {
    color: var(--lg-light-backcolor);
    height: 20px;
    padding: 0px 5px;
    font-size: 30px;
    cursor: pointer;
    border-radius: 6px;
  }
}

.Customer__MDB_AddContBox_Body {
  padding: 12px 16px;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 4px;

  p {
    color: var(--lg-greydark-textcolor);
    font-size: 12px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}

.Biz__Customer_User_CBox {
  background-color: #fff;
  display: flex;
  align-items: stretch !important;
  flex-direction: column;
  border: 1px solid #d5d8dc;
  padding: 10px 14px 14px 14px;
  border-radius: 0.18rem;
  position: relative;
}

.Biz__Customer_User_MCbox {
  justify-content: flex-end !important;
  display: flex !important;
  height: 24px;
  cursor: pointer;
}

.Biz__Customer_UserTxt1 {
  font-size: 16px !important;
  color: var(--Main-Dark-text-color);
  font-weight: 500 !important;
  margin-bottom: 0px;
  font-family: Poppins, Helvetica, "sans-serif";
  white-space: nowrap;
}

.Biz__Customer_UserTxt2 {
  font-size: 11px !important;
  color: #b5b5c3 !important;
  font-family: Poppins, Helvetica, "sans-serif";
}

.Biz__Customer_User_BoxP1 {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 1rem !important;
}

.Biz__Customer_User_BoxP2 {
  display: flex !important;
  flex-direction: column;
  width: 100%;
}

.Biz__Customer_User_CNBox {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;

  label {
    font-size: 12px;
    color: var(--Main-Dark-text-color);
    font-weight: 500 !important;
    padding-right: 16px;
    font-family: Poppins, Helvetica, "sans-serif";

    &::after {
      content: ":";
      padding-left: 4px;
    }
  }

  p {
    font-size: 12px;
    color: #b5b5c3 !important;
    font-weight: 400 !important;
    font-family: Poppins, Helvetica, "sans-serif";
  }
}

.Biz__Customer_UserImgBox {
  margin-right: 1rem !important;
}

.Biz__Customer_UserImgSymbol {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Biz_UserSymbolImg {
  width: 66px;
  height: 66px;
}

.Biz__Customer_UserTxtBox {
  display: flex !important;
  flex-direction: column !important;
}

.Biz__Customer_User_setbar {
  position: absolute;
  z-index: 9999;
  top: 34px;
  right: 1px;
  width: 180px;
  background: #fff;
  padding: 6px;
  box-shadow: 1px 1px 5px #c1c1c1;
}

.Biz__Customer_Address_setbar {
  position: absolute;
  z-index: 9999;
  top: 30px;
  right: 1px;
  width: 180px;
  background: #fff;
  padding: 6px;
  box-shadow: 1px 1px 5px #c1c1c1;
}

.Biz__Customer_User_MCbox .Biz_Icon {
  font-size: 30px;
  color: var(--Main-light-text-color);
  padding: 0px 4px;
  border-radius: 0.18rem;
  height: 24px;
  background-color: var(--Sub-Header-Color);
}

.Customer__MDB_ContentDiv {
  display: none;
}

.ActiveCustomer__MDB_ContentDiv {
  display: grid;
}

.Customer_BankContBox {
  border: 0;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  border-top: none !important;
  border: 1px solid #ebedef;
  background-clip: border-box;

  //&:nth-child(1) .Customer_BankContBox_Header {
  .Customer_BankContBox_Header {
    background-color: #fdb660;
  }
}

.Customer_BankContBox_Header {
  background-color: #abb2b9;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 10px;

  h4 {
    color: #fff;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
  }

  .Biz_Icon {
    color: var(--lg-light-backcolor);
    height: 20px;
    padding: 0px 5px;
    font-size: 30px;
    cursor: pointer;
    border-radius: 6px;
  }
}

.Biz__Customer_Bank_setbar {
  position: absolute;
  z-index: 9999;
  top: 30px;
  right: 1px;
  width: 180px;
  background: #fff;
  padding: 6px;
  box-shadow: 1px 1px 5px #c1c1c1;
}

.Biz__Customer_Bank_BoxP2 {
  display: flex !important;
  flex-direction: column;
  width: 100%;
}

.Customer_BankContBox_Body {
  padding: 5px 10px 0px 10px;
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 4px;
}

.Biz__Customer_Bank_CNBox {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;

  label {
    font-size: 12px;
    color: var(--Main-Dark-text-color);
    font-weight: 500 !important;
    padding-right: 16px;
    font-family: Poppins, Helvetica, "sans-serif";

    &::after {
      content: ":";
      padding-left: 4px;
    }
  }

  p {
    font-size: 12px;
    color: #b5b5c3 !important;
    font-weight: 400 !important;
    font-family: Poppins, Helvetica, "sans-serif";
  }
}

.Biz_Customer_Empty_dataBox {
  margin-top: 50px;
  display: grid;
  align-items: center;
  justify-content: center;

  img {
    height: 80px;
    display: flex;
    margin: 0 auto;
  }

  p {
    font-size: 14px;
    margin-top: 12px;
    color: #505050;
    font-family: "Poppins", sans-serif;
  }
}

/* Add Form Popup */

.Biz__Customer_AddPopup_overlay {
  background-color: rgba(9, 30, 66, 0.28);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.Biz__Customer_AddPopup_overlay_PopupAnim {
  transform: scale(1.01);
}

.Biz__Customer_AddPopup_Box {
  background: #fff;
  z-index: 99999;
  position: fixed;
  left: auto;
  right: 0px;
  margin: auto;
  width: max-content;
  height: max-content;
  bottom: 0px;
  overflow: auto;
  box-shadow: rgba(0, 27, 71, 0.12) 0px 12px 32px;
  border-radius: 2px;
  border: 2px solid #d6dbdf;
  animation: animatetop 0.5s;
  top: 0px;
  max-width: 100vw;
  height: 100vh;
  overflow: initial;
  min-width: 780px;
  display: grid;
  grid-template-rows: max-content auto;
}

@keyframes animatetop {
  0% {
    right: -100vh;
    opacity: 0;
  }

  100% {
    right: 0px;
    opacity: 1;
  }
}

/* Add Form Popup */

/* Add Form Body */

.Biz__Customer_AddPopup_Header {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  height: 40px;
  background: #f2f3f5;
  border-bottom: 1px solid #ced1d6;
  line-height: 20px;
  letter-spacing: 0px;

  h2 {
    white-space: nowrap;
    font-size: 15px;
    color: #34495e;
    font-weight: 600;
    user-select: none !important;
    -webkit-user-drag: none !important;
    font-family: "Poppins", sans-serif;
  }
}

a.Biz__Customer_AddPopup_HeaderCloseBtn {
  height: 21px;
  user-select: none !important;
  -webkit-user-drag: none !important;
}

.Biz__Customer_AddPopup_HeaderCloseBtn {
  .Biz_Icon {
    height: 21px;
    width: 22px;
    color: var(--Main-Primary-CancelBtn-Color);
    padding: 3px;
    border-radius: 6px;
    cursor: pointer;
    user-select: none !important;
    -webkit-user-drag: none !important;
  }

  &:hover .Biz_Icon {
    color: var(--Main-Primary-red-Color) !important;
  }
}

form.Biz__Customer_AddPopup_Content {
  display: grid;
  grid-template-rows: auto max-content;
}

.Biz__Customer_AddPopup_FormContent {
  padding: 18px;
  display: grid;
  grid-template-rows: max-content max-content auto;
  row-gap: 10px;
  max-width: 100vw;
  height: calc(100vh - 48px - 44px);
  overflow: auto;
}

.Biz__Customer_AddPopup_FormGroup {
  display: grid;
  grid-template-columns: repeat(2, max-content minmax(280px, auto));
  grid-gap: 0.8em;
  grid-auto-flow: dense;
  align-items: center;
}

.Biz__Customer_AddPopup_FormGroupV2 {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0.3em 0.6em;
  grid-auto-flow: dense;
  align-items: center;
}

.Biz__Customer_AddPopup_LabelTxt {
  font-size: 13px;
  color: #555;
  line-height: 1.5;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
  user-select: none !important;
  -webkit-user-drag: none !important;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  display: grid;
  grid-template-rows: 32px auto;
  align-items: center;
  height: 100%;
}

.Biz__Customer_AddPopup_LabelTxtV2 {
  font-size: 13px;
  color: #555;
  line-height: 1.5;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
  user-select: none !important;
  -webkit-user-drag: none !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:before {
    content: "";
    border-top: 1px solid #85929e;
    margin: 0 20px 0 0px;
    flex: 1 0 20px;
  }

  &:after {
    content: "";
    border-top: 1px solid #85929e;
    margin: 0 0px 0 20px;
    flex: 1 0 20px;
  }
}

.Biz__Customer_AddPopup_Inputbox {
  width: 100%;
  height: 100%;
  border: 1px solid #d6dbdf;
  padding: 8px;
  border-radius: 0px !important;
  outline: none;
  font-size: 12px;
  color: #3f4254;
  font-weight: 400;
  height: 30px;
}

.Biz__Customer_AddPopup_Req {
  text-decoration: 2px underline red;
}

.Biz__Customer_AddPopup_Inputbox {
  &:focus {
    border: 1px solid #0071e3 !important;
    box-shadow: 0 0 0 2px rgba(0, 125, 250, 0.3);
  }

  &::placeholder {
    color: #3f4254 !important;
    font-weight: 400;
  }
}

.Biz__Customer_AddPopup_FormContentBoxV1 {
  display: grid;
  grid-template-rows: max-content;
}

.Biz__Customer_AddPopup_FormContentSectionHead {
  background: #f3f6f9;
  padding: 6px 12px;
  margin: 12px 0px 6px 0px;
  border-top: 1px solid #d6dbdf;
  border-bottom: 1px solid #d6dbdf;
  height: 60px;
}

.Biz__Customer_AddPopup_TabContentBoxPt1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 16px;
}

.Biz__Customer_AddPopup_TabContentBox {
  display: none;
}

.Biz__Customer_AddPopup_TabContentBoxActive {
  display: block;
}

.Biz__Customer_AddPopup_NameInputGroup {
  display: grid;
  grid-template-columns: 1.2fr 2fr 2fr;
  column-gap: 8px;
}

.Biz__Customer_AddPopup_CommonInputGroup {
  display: grid;
  grid-template-columns: 140px 2fr;
  column-gap: 12px;
  align-items: center;
}

.Biz__Customer_AddPopup_FormContentBoxV2 {
  margin: 0px 0px 12px 0px;
  display: grid;
  grid-auto-rows: max-content;
  row-gap: 20px;
}

.Customer_AddPopup__TabPanel {
  display: flex;
  flex-direction: row;
}

.Customer_AddPopup__TabList {
  color: #1685cb;
  font-size: 14px;
  list-style: none;
  display: grid;
  align-items: center;
  padding: 5px 12px;
  margin-right: 4px;
  cursor: pointer;
  user-select: none !important;
  border-radius: 0.18rem;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
  line-height: 20px;
  font-weight: 500;
}

.ActiveCustomer_AddPopup__TabList {
  background-color: #1685cb;
  color: #fff;
}

.Biz__Customer_AddPopup_FormContentSectionHead {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    white-space: nowrap;
    font-size: 15px;
    color: #34495e;
    font-weight: 500;
    user-select: none !important;
    -webkit-user-drag: none !important;
    font-family: "Poppins", sans-serif;
  }

  button {
    padding: 3px 12px;
    font-size: 13px;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    grid-column-gap: 8px;
    display: grid;
    grid-template-columns: max-content;
    align-items: center;
    font-family: "Poppins", sans-serif;
    background-color: var(--Main-Secondary-color);

    &:hover {
      opacity: 0.8;
    }
  }
}

.Biz__Customer_AddPopupFooter {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 16px;
  height: 44px;
  background: #f2f3f5;
  border-top: 1px solid #ced1d6;
  line-height: 20px;
  letter-spacing: 0px;

  button {
    padding: 6px 12px;
    font-size: 13px;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    grid-column-gap: 8px;
    display: grid;
    grid-template-columns: max-content;
    align-items: center;
    font-family: "Poppins", sans-serif;
    background-color: var(--Main-Primary-CancelBtn-Color);

    &:nth-child(1) {
      margin-right: 8px;
      background-color: var(--Main-Secondary-color);

      &:hover {
        opacity: 0.7;
      }
    }

    &:nth-child(2):hover {
      opacity: 0.7;
    }
  }
}

/* Add Form Body */

/* Button Loader Start */

.FormBtnActive {
  pointer-events: none !important;
}

.BtnTxt {
  color: #fff !important;
}

.FormBtnActive .BtnTxt {
  display: none;
}

.btn__spinner {
  display: block;
  align-items: center;
  justify-content: center;
}

.btn__bounce1,
.btn__bounce2,
.btn__bounce3 {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  animation: btn__animation 1.4s ease-in-out infinite both;
}

.btn__bounce1 {
  animation-delay: -0.32s;
  margin-right: 2px;
}

.btn__bounce2 {
  animation-delay: -0.16s;
  margin-right: 2px;
}

@keyframes btn__animation {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

/* Button Loader End */

.Biz__Customer_AddAddressBtn {
  padding: 4px 14px;
  height: 26px;
  font-size: 12px;
  margin-top: -8px;
  border: none;
  border-radius: 3px;
  color: var(--Main-light-text-color);
  cursor: pointer;
  grid-column-gap: 4px;
  display: grid;
  align-items: center;
  background-color: var(--Main-Secondary-color);
}

/* --------------- Address Assignment --------------- */

.Biz__Customer_AddressAssignment {
  min-width: 820px;
  display: grid;
  grid-template-columns: 1fr minmax(80px, max-content) 1fr;

  .Biz__Customer_AddressAssignment_ListV1,
  .Biz__Customer_AddressAssignment_ListV2 {
    border: 1px solid #d6dbdf;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
  }

  ul {
    list-style-type: none;
    height: 320px;
    overflow: auto;

    li {
      border-bottom: 1px solid #d6dbdf;
      padding: 7px 8px;
      font-size: 12px;
      color: #444858;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }

  .Biz__Customer_AddressAssignment_buttons {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
    gap: 6px;
    justify-content: center;

    .Biz__Customer_AddressAssignment_listbox__button {
      padding: 4px 10px;
      font-size: 12px;
      border-radius: 2px;
      margin: 2px 0px;
      color: var(--Main-light-text-color);
      cursor: pointer;
      border: 1px solid #3d8bda;
      background-image: linear-gradient(var(--Main-Primary-Color), #3d8bda);
      font-size: 11px;
      padding: 4px 8px;
      color: #fff;
      transition: all 0.3s ease;
    }
  }

  .Biz__Customer_AddressAssignment_ListHead {
    background: #f2f3f5;
    border-bottom: 1px solid #ced1d6;
    padding: 5px 6px 6px 12px;
    border-radius: 2px 2px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span {
      color: #34495e;
      font-size: 13px;
      font-weight: 600;
    }

    .DynBiz_PageTitle_SearchInputBox {
      padding: 2px 8px !important;
      height: 24px !important;
      width: 130px !important;
    }

    .DynBiz_PageTitle_SearchBoxBtn {
      padding: 3px 8px !important;
      width: 58px !important;
      font-size: 11px !important;
    }
  }
}

/* --------------- Address Assignment --------------- */

.Biz_Bm_Company_ManageLocationTypePopup_AssButton::before {
  background: #ccc;
  background: #8198b4;
  color: #fff;
  font-weight: normal;
  float: left;
  width: 2em;
  text-align: center;
  font-size: 1em;
  margin: 0 1em 0 -1em;
  padding: 0 0.2em;
  -moz-border-radius: 0.15em 0 0 0.15em;
  -webkit-border-radius: 0.15em 0 0 0.15em;
  border-radius: 0.15em 0 0 0.15em;
  pointer-events: none;
  border-right: 1px solid #8198b4;
}

.Biz_Bm_Company_ManageLocationTypePopup_AssButton.Assigned:before {
  content: "✖";
  background: red;
}

.Biz_Bm_Company_ManageLocationTypePopup_AssButton.UnAssigned:before {
  content: "✔";
  background: rgb(40, 189, 139);
}

.Biz_Bm_Company_ManageLocationTypePopup_AssButton {
  display: inline-block;
  white-space: nowrap;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(#ddd)
  );
  border: 1px solid #8198b4;
  cursor: pointer;
  padding: 0 1em;
  font: bold 0.7em/2em Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #5d6672;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  -moz-border-radius: 0.2em;
  -webkit-border-radius: 0.2em;
  border-radius: 0.2em;
  -moz-box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.8) inset,
    0 1px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.8) inset,
    0 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.8) inset,
    0 1px 0 rgba(0, 0, 0, 0.1);
  margin-top: 0px;
}

.Customer__MDB_AddContBox_Deleted {
  background: #d6dbdf !important;
}

/* ********************************** document upload popup css ************************************* */
.AddDoc_Div {
  display: grid;
  grid-template-rows: max-content auto;

  .DocumentAdd {
    padding: 0px 5px;
    display: grid;
    grid-template-columns: auto 70px 80px;
    align-items: center;

    .Icon {
      background-color: #888b8b;
      color: #fff;
      padding: 5px 5px;
      height: 28px;
      align-items: center;
    }

    > button {
      margin-top: -3px;
    }
  }

  .DataGrid {
    display: grid;
  }
}

.AddDocumentSaveButton {
  display: grid;
  align-items: flex-start;
  height: 100%;
  padding-top: 10px;
  padding-left: 6px;
}

.FileViewer {
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: relative;
}
.Biz__LeadPageTitle2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1px;
  gap: 8px;
  padding: 0 10px;
  background: #047081;
  border: 1px solid #ffffff;
  border-left: 0px !important;

  li {
    color: #fff !important;
    color: #474c4e;
    font-size: 14px;
    list-style: none;
    display: grid;
    align-items: center;
    padding: 3.4px 12px;
    margin-right: 4px;
    cursor: pointer;
    -webkit-user-select: none !important;
    user-select: none !important;
    border-radius: 0.18rem;
    white-space: nowrap;
    font-family: "Poppins", sans-serif;
    line-height: 20px;
    font-weight: 500;
  }

  .active {
    color: #12344d !important;
    background-color: #fff;
  }

  .Lead__TabPanel {
    display: flex;
    flex-direction: row;
  }
}
.AddDocumentDiv {
  display: grid;
  grid-template-rows: max-content max-content auto;
}

.CounterDesc {
  color: #fff;
  background-color: #01a3fd;
  border-radius: 0px 0px 4px 4px;
  width: max-content;
  font-size: 10px;
  padding: 1px 8px 1px;
  justify-content: center;
  font-weight: 400;
  -webkit-user-select: none;
  user-select: none;
  grid-column: span 2;
  justify-content: center;
  margin-left: auto;
  min-width: 50px;
  text-align: right;
  position: absolute;
  top: 32px;
  right: 0px;
}

.AddDocumentSaveButton {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 12px;
  justify-content: right;
  margin: 3px 0px 5px 0px;
  padding-right: 10px;
  border-bottom: 1px solid #ccc;
}
